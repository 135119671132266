<template>
<form @submit.prevent="">
    <div v-if="isMobile" class="form-row" style="margin-bottom: 10px;">
        <v-text-field class="mobile-app-input mobile-app-input-modal" label="عنوان لینک" id="link-name" v-model="link.title"></v-text-field>
    </div>
    <div class="form-row">
        <v-text-field v-if="isMobile" class="mobile-app-input mobile-app-input-modal" label="آدرس لینک" id="link-url" v-model="link.url"></v-text-field>

        <div v-if="!isMobile" class="col-12">
            <input type="text" id="link-name" placeholder="عنوان لینک" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="link.title">
        </div>
        <div v-if="!isMobile" class="col-12" style="margin-top: 15px;margin-bottom: 15px;">
            <input type="text" id="link-url" placeholder="آدرس لینک" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="link.url">
        </div>
    </div>
    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveChanges()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary"><i class="uil uil-save"></i> ذخیره تغییرات</a>
        </div>
    </div>
</form>
</template>

<script>
// این کامپوننت مودال افزودن علاقه مندی ها می باشد
export default {
    name: 'FavoriteAdd',
    props: ['data'],
    components: {},
    data: function () {
        return {
            action: 'add',
            link: {
                title: '',
                url: '',
            },
            isMobile:false
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length) {
                this.loadData();
            }
        }, { immediate: true });
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // تزریق و مقدار دهی اولیه به کامپوننت
        loadData() {
            this.action = this.data.action;

            if (this.action == 'add') {
                this.link.title = '';
                this.link.url = 'https://www.';
            } else {
                this.link = this.data.link;
            }
        },
        // این متد برای ذخیره سازی اطلاعات استفاده میشود
        // به کلاس پرنت نیز کال بک می کند
        saveChanges() {
            if (this.link.title && this.link.url != 'https://www.') {
                let event = (this.action == 'edit') ? 'editLink' : 'addLink';
                this.$parent.emitData(event, { link: this.link }, true);
            }
        },
    },
}
</script>
